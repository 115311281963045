@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  html {
    @apply text-black;
  }
}

@layer components {
  .btn {
    @apply flex items-center justify-center font-medium rounded-full cursor-pointer;
  }
  .btn-default {
    @apply px-10 py-3 text-base lg:px-12 lg:py-4;
  }
  .btn-large {
    @apply py-5 text-xl px-14;
  }
  .btn-primary {
    @apply text-white duration-300 ease-out border bg-primary-blue border-primary-blue;
  }
  .btn-primary:hover {
    @apply bg-white text-primary-blue;
  }
  .btn-primary-inverted {
    @apply duration-300 ease-out bg-white border border-white text-primary-blue;
  }
  .btn-primary-inverted:hover {
    @apply text-white bg-primary-blue;
  }
  .form-container {
    @apply flex flex-col items-start w-full gap-2;
  }
  .input-container {
    @apply flex items-center w-full gap-4 px-6 duration-300 ease-out bg-white border rounded-lg border-primary-blue border-opacity-30;
  }
  .input-container:focus-within,
  .input-container:focus {
    @apply border-opacity-100;
  }
  .form-label {
    @apply font-semibold;
  }
  .form-input {
    @apply w-full py-4 focus:outline-none;
  }
}

@layer utilities {
  .error-message {
    @apply text-red-500
  }
  .mini-vert {
    writing-mode: vertical-rl;
    transform: rotate(deg);
    white-space: nowrap;
    display: inline-block;
    overflow: visible;
    display: flex;
    gap: 8px;
    color: #222;
    align-items: center;
  }
}
